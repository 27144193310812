<template>
  <div style="display: flex;">

    <el-container>

      <el-main>
        <head-layout :head-btn-options="headBtnOptions" :head-title="$t('cip.dc.dataobject.title.name')"
          @head-add="AddObjTable()" @head-back="toback" @head-save-back="AddObjTable('back')">
        </head-layout>






        <el-form :model="listAdd" label-position="right" label-width="100px" style="padding: 10px;">

          <el-collapse v-model="activeName" style="margintop: 10px">
            <el-collapse-item name="1">
              <template slot="title">
                <i class="avue-group__title avue-group__header" style="
              color: #999999 !important;
              font-style: normal;
              padding: 0 10px;
            "><i class="el-icon-document" style="font-size: 20px; padding: 0 10px"></i>
                  {{ $t('cip.dc.dataobjDComission.basicinfo') }}
                </i>
              </template>

              <el-row>
                <el-col :span="8">
                  <el-form-item :label="$t('cip.dc.dataobject.field.type')" :label-width="formLabelWidth">
                    <el-select filterable v-model="listAdd.sourceType" :placeholder="$t('cip.dc.dataobject.field.type')"
                      @change="ChooseType($event)">
                      <el-option v-for="item in TypeOp" :key="item.label" :label="item.label"
                        :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('cip.dc.dataobject.field.code')" :label-width="formLabelWidth">
                    <el-input v-model="listAdd.code" :placeholder="$t('cip.dc.dataobject.msg.p2')"
                      autocomplete="off"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('cip.dc.dataobject.field.identification')" :label-width="formLabelWidth">
                    <el-input v-model="listAdd.label" :placeholder="$t('cip.dc.dataobject.msg.p3')"
                      autocomplete="off"></el-input>
                  </el-form-item>

                </el-col>

              </el-row>


              <el-row v-if="listAdd.sourceType == 1">
                <el-col :span="8">
                  <el-form-item :label="$t('cip.dc.dataobject.field.databank')" :label-width="formLabelWidth">
                    <el-select filterable v-model="listAdd.metadataInfoDTO.metadataSourceId"
                      :placeholder="$t('cip.dc.dataobject.field.databank')" @change="ChooseSJKOP($event)">
                      <!-- SJKOP -->
                      <el-option v-for="item in SJKOP" :key="item.id" :label="item.sourceName"
                        :value="item.id"></el-option>
                    </el-select>

                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <el-form-item :label="$t('cip.dc.dataobject.field.tableName')" :label-width="formLabelWidth">
                    <el-select filterable v-model="listAdd.metadataInfoDTO.metadataTableId"
                      :placeholder="$t('cip.dc.dataobject.field.tableName')" @change="ChooseSBMOP($event)">
                      <el-option v-for="item in SBMOP" :key="item.id" :label="toStr(item)" :value="item.id"></el-option>
                    </el-select>

                  </el-form-item>

                </el-col>
              </el-row>

              <el-row>
                <el-col :span="8" v-if="listAdd.sourceType == 2">
                  <el-form-item :label="$t('cip.dc.dataobject.field.Apiinterface')" :label-width="formLabelWidth">
                    <el-select filterable v-model="listAdd.metadataInfoDTO.apiId"
                      :placeholder="$t('cip.dc.dataobject.field.Apiinterface')" @change="ChooseAPIOP($event)">
                      <el-option v-for="item in APIOP" v-show="item.status == 2" :key="item.id"
                        :disabled="item.status != 2" :label="item.apiName" :value="item.id"></el-option>
                    </el-select>

                  </el-form-item>
                </el-col>
              </el-row>


              <el-row>
                <el-col :span="8" v-if="listAdd.sourceType == 3">
                  <el-form-item :label="$t('cip.dc.dataobjDComission.indexCode')" :label-width="formLabelWidth">
                    <el-select v-model="listAdd.metadataInfoDTO.indexCode" filterable clearable
                      :placeholder="$t('cip.dc.dataobjDComission.placeholder1')" @change="changezb">
                      <el-option v-for="item in zbList" :key="item.indexCode" :label="item.indexName"
                        :value="item.indexCode">
                      </el-option>
                    </el-select>




                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="8" v-if="listAdd.sourceType == 4">
                  <el-form-item :label="$t('cip.dc.dataobjDComission.indexCode')" :label-width="formLabelWidth">
                    <el-select v-model="listAdd.metadataInfoDTO.indexCode" filterable clearable
                      :placeholder="$t('cip.dc.dataobjDComission.placeholder1')" @change="changezbjk">
                      <el-option v-for="item in zbjkList" :key="item.id" :label="item.intfName"
                        :value="item.intfCode">
                      </el-option>
                    </el-select>




                  </el-form-item>
                </el-col>
              </el-row>


              <el-row>
                <el-col :span="16">
                  <el-form-item :label="$t('cip.dc.dataobject.field.remark')" :label-width="formLabelWidth">
                    <el-input v-model="listAdd.remark" :placeholder="$t('cip.dc.dataobject.msg.p4')" :rows="3"
                      autocomplete="off" type="textarea"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>



            </el-collapse-item>









          </el-collapse>


















          <div v-if="listAdd.sourceType == 1">

            <el-tabs type="border-card">
              <el-tab-pane :label="$t('cip.dc.dataobject.field.saveField')">
                <el-form-item label-width="1">

                  <el-table ref="SBZDbcTable" :data="SBZDbc" border size="small" style="width: 100%">
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column :label="$t('cip.dc.dataobject.field.fieldName')" prop="columnName">
                    </el-table-column>

                    <el-table-column :label="$t('cip.dc.dataobject.field.fieldComments')" prop="columnComment">
                    </el-table-column>

                  </el-table>
                </el-form-item>
              </el-tab-pane>
              <el-tab-pane :label="$t('cip.dc.dataobject.field.ConditionField')">
                <el-form-item label-width="1">

                  <el-table ref="SBZDtjTable" :data="SBZDtj" border size="small" style="width: 100%">
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column :label="$t('cip.dc.dataobject.field.fieldName')" prop="columnName" width="180">
                    </el-table-column>

                    <el-table-column :label="$t('cip.dc.dataobject.field.fieldComments')" prop="columnComment">
                    </el-table-column>

                    <!-- dataType datetime  timestam-->

                    <el-table-column :label="$t('cip.dc.dataobjDComission.type')" width="160">
                      <template slot-scope="scope">
                        <el-select v-if="scope.row.dataType == 'datetime' || scope.row.dataType == 'timestam'"
                          v-model="scope.row.TimeType" :placeholder="$t('cip.dc.DComission.selectPlaceholder')"
                          @change="changeTimeType($event, scope.row)">
                          <el-option :label="$t('cip.dc.offlineDComission.gdtime')" value="1">
                          </el-option>
                          <el-option :label="$t('cip.dc.offlineDComission.dttime')" value="2">
                          </el-option>
                        </el-select>

                        <el-select filterable v-if="scope.row.dataType != 'datetime' && scope.row.dataType != 'timestam'"
                          v-model="scope.row.QueryType" :placeholder="$t('cip.dc.DComission.selectPlaceholder')"
                          @change="changeQueryType($event, scope.row)">
                          <el-option v-for="(item, index) in queryTypeList" :key="index" :label="item.label"
                            :value="item.value">
                          </el-option>


                        </el-select>
                      </template>
                    </el-table-column>



                    <!-- <el-table-column prop="dataDefault" :label="$t('cip.dc.dataobject.field.default')" width="180">
                </el-table-column> -->
                    <el-table-column :label="$t('cip.dc.dataobject.field.enterValue')" prop="BCinput" width="360">
                      <template slot-scope="scope">
                        <!-- <el-input v-model="scope.row.BCinput" size="small"> </el-input> -->
                        <div v-if="scope.row.QueryType && scope.row.QueryType !== '-1'">
                          <div v-if="scope.row.QueryType == '3'">
                            <el-input v-model="scope.row.startValue" :placeholder="$t('cip.dc.offlineDComission.star')"
                              style="width: 50%;"></el-input>
                            <el-input v-model="scope.row.endValue" :placeholder="$t('cip.dc.offlineDComission.end')"
                              style="width: 50%;"></el-input>
                          </div>
                          <el-input v-if="scope.row.QueryType !== '3'" v-model="scope.row.BCinput"></el-input>



                        </div>
                        <div v-else>
                          <el-date-picker v-if="scope.row.TimeType == '1'" v-model="scope.row.gdtime"
                            :range-separator="$t('cip.dc.DComission.to')"
                            :start-placeholder="$t('cip.dc.DComission.startdate')"
                            :end-placeholder="$t('cip.dc.DComission.enddate')" style="width: 350px;" type="daterange"
                            value-format="yyyy-MM-dd">
                          </el-date-picker>

                          <el-select filterable v-if="scope.row.TimeType == '2'" v-model="scope.row.DTime"
                            :placeholder="$t('cip.dc.DComission.selectPlaceholder')">
                            <el-option v-for="item in DtimeType" :key="item.value_name" :label="item.value_name"
                              :value="item.value_code">
                            </el-option>

                          </el-select>
                        </div>
                      </template>
                    </el-table-column>

                  </el-table>


                </el-form-item>
              </el-tab-pane>
              <el-tab-pane :label="$t('cip.dc.dataobjDComission.sortField')">
                <el-form-item label-width="1">
                  <el-table ref="pxTable" :data="SBZDpx" border size="small" style="width: 100%">
                    <el-table-column type="selection" width="55"> </el-table-column>
                    <el-table-column prop="columnName" :label="$t('AiSuanfa.detail.zh')">
                    </el-table-column>
                    <!-- <el-table-column
              prop="columnComment"
              :label="$t('AiSuanfa.detail.field')"
            >
            </el-table-column> -->
                    <el-table-column prop="dataType" :label="$t('AiSuanfa.detail.dataType')">
                    </el-table-column>
                    <el-table-column prop="dataType" :label="$t('cip.dc.dataobjDComission.sort')">
                      <template slot-scope="scope">
                        <el-select class="tableSclect" v-model="scope.row.sortRule"
                          :placeholder="$t('cip.dc.DComission.selectPlaceholder')">
                          <el-option :label="$t('cip.dc.dataobjDComission.upsort')" value="0"> </el-option>
                          <el-option :label="$t('cip.dc.dataobjDComission.downsort')" value="1"> </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form-item>
              </el-tab-pane>

            </el-tabs>








            <!-- 暂时隐藏 -->
            <el-form-item :label="$t('cip.dc.dataobject.field.comparisonField')" :label-width="formLabelWidth"
              style="display: none;">
              <el-table ref="SBZDdbTable" :data="SBZDdb" border size="small" style="width: 100%">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column :label="$t('cip.dc.dataobject.field.fieldName')" prop="columnName">
                </el-table-column>

                <el-table-column :label="$t('cip.dc.dataobject.field.fieldComments')" prop="columnComment">
                </el-table-column>

              </el-table>
            </el-form-item>







          </div>




          <div v-if="listAdd.sourceType == 2">

            <el-tabs type="border-card">
              <el-tab-pane :label="$t('cip.dc.dataobject.field.saveField')">
                <el-form-item label-width="1">

                  <el-table ref="SBZDbcTable2" :data="SBZDbc" border size="small" style="width: 100%">
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column :label="$t('cip.dc.dataobject.field.fieldName')" prop="fieldName">
                    </el-table-column>

                    <el-table-column :label="$t('cip.dc.dataobject.field.fieldComments')" prop="fieldComment">
                    </el-table-column>

                  </el-table>
                </el-form-item>

              </el-tab-pane>
              <el-tab-pane :label="$t('cip.dc.dataobject.field.ConditionField')">
                <el-form-item label-width="1">

                  <el-table ref="SBZDtjTable2" :data="SBZDtj" border size="small" style="width: 100%">
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column :label="$t('cip.dc.dataobject.field.fieldName')" prop="paramName" width="180">
                    </el-table-column>

                    <el-table-column :label="$t('cip.dc.dataobject.field.fieldComments')" prop="paramComment">
                    </el-table-column>

                    <el-table-column :label="$t('cip.dc.dataobject.field.default')" prop="dataDefault" width="180">
                    </el-table-column>
                    <el-table-column :label="$t('cip.dc.dataobject.field.enterValue')" prop="BCinput">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.BCinput" size="small"></el-input>
                      </template>
                    </el-table-column>
                  </el-table>


                </el-form-item>
              </el-tab-pane>

            </el-tabs>







            <!-- 暂时隐藏 -->
            <el-form-item :label="$t('cip.dc.dataobject.field.comparisonField')" :label-width="formLabelWidth"
              style="display: none;">
              <el-table ref="SBZDdbTable2" :data="SBZDdb" border size="small" style="width: 100%">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column :label="$t('cip.dc.dataobject.field.fieldName')" prop="fieldName">
                </el-table-column>

                <el-table-column :label="$t('cip.dc.dataobject.field.fieldComments')" prop="fieldComment">
                </el-table-column>

              </el-table>
            </el-form-item>




          </div>



          <div v-if="listAdd.sourceType == 3">

            <el-tabs type="border-card">
              <el-tab-pane :label="$t('cip.dc.dataobject.field.saveField')">
                <el-form-item label-width="1">

                  <el-table ref="SBZDbcTable3" :data="SBZDbc" border size="small" style="width: 100%">
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column :label="$t('cip.dc.dataobject.field.fieldName')" prop="fieldName">
                    </el-table-column>

                    <!-- <el-table-column :label="$t('cip.dc.dataobject.field.fieldComments')" prop="fieldComment">
    </el-table-column> -->

                  </el-table>
                </el-form-item>

              </el-tab-pane>

              <el-tab-pane :label="$t('cip.dc.dataobject.field.ConditionField')">
                <el-form-item label-width="1">

                  <el-table ref="SBZDtjTable3" :data="SBZDtj" border size="small" style="width: 100%">
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column :label="$t('cip.dc.dataobject.field.fieldName')" prop="paramName" width="180">
                    </el-table-column>

                    <el-table-column :label="$t('cip.dc.dataobject.field.fieldComments')" prop="paramComment">
                    </el-table-column>




                    <el-table-column :label="$t('cip.dc.dataobjDComission.type')" width="200">
                      <template slot-scope="scope">
                        <el-select v-model="scope.row.TimeType" :placeholder="$t('cip.dc.DComission.selectPlaceholder')"
                          @change="changeTimeType($event, scope.row)">
                          <el-option :label="$t('cip.dc.offlineDComission.gdtime')" value="1">
                          </el-option>
                          <el-option :label="$t('cip.dc.offlineDComission.dttime')" value="2">
                          </el-option>
                        </el-select>


                      </template>
                    </el-table-column>




                    <el-table-column :label="$t('cip.dc.dataobject.field.enterValue')" prop="BCinput" width="460">
                      <template slot-scope="scope">


                        <div>
                          <el-date-picker v-if="scope.row.TimeType == '1'" v-model="scope.row.gdtime"
                            :range-separator="$t('cip.dc.DComission.to')"
                            :start-placeholder="$t('cip.dc.DComission.startdate')"
                            :end-placeholder="$t('cip.dc.DComission.enddate')" style="width: 420px;" type="daterange"
                            value-format="yyyy-MM-dd">
                          </el-date-picker>

                          <el-select filterable v-if="scope.row.TimeType == '2'" v-model="scope.row.DTime"
                            :placeholder="$t('cip.dc.DComission.selectPlaceholder')">
                            <el-option v-for="item in DtimeType" :key="item.value_name" :label="item.value_name"
                              :value="item.value_code">
                            </el-option>

                          </el-select>
                        </div>
                      </template>
                    </el-table-column>


                  </el-table>


                </el-form-item>
              </el-tab-pane>


            </el-tabs>












          </div>








        </el-form>




      </el-main>
    </el-container>



    <basic-container>
























    </basic-container>


  </div>
</template>

<script>
import {
  AddObjectList,
  DelObjectList,
  DelObjTree,
  getColumnsList,
  getDataApisList,
  getDataApisXQ,
  getDataOBJXQ,
  getDataTree,
  getDTime,
  getSourcesList2,
  getSourcesTableList2,
  ObjectList,
  SaveAddTree,
  seeData,
  objgetzb,
  objgetzbjk,
  getzbfieldList,
  getzbjkfieldList,
} from "@/api/dataAcquisition/index";
import { getPowerconsumption3 } from '@/api/Ai';
import seeUrl from '@/api/Ai/seeurl';

import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import CommonTree from "@/views/components/com_tree/index"
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout"

export default {
  watch: {
    // filterText(val) {
    //   this.$refs.tree.filter(val);
    // }
  },
  components: {
    CommonTree, GridLayout, HeadLayout
  },
  data() {
    return {
      zbList: [],
      zbjkList:[],
      zbloading: false,
      activeName: "1",
      DtimeType: [],
      searchTitle: 'name',
      DelLsitid: "",

      tableData4: [],
      seetype: '',
      tableData4List: [],
      filterText: '',

      addTreeData: {
        parentId: "0",
        name: "",
        type: "0"
      },


      TypeOp: [
        {
          label: this.$t('cip.dc.dataobjDComission.shujuku'),
          value: 1

        },
        {
          label: this.$t('cip.dc.dataobjDComission.api'),
          value: 2

        },
        {
          label: this.$t('cip.dc.dataobjDComission.indexzb'),
          value: 3
        },
        {
          label: "指标接口",
          value: 4
        },
      ],
      listAdd: {
        modelId: "",
        code: "",
        label: "",
        id: "",//主键ID 修改时传入
        remark: "",  //备注
        sourceType: null,  //类型 1-数据库 2-api
        dataObjectTypeId: "", //数据对象类型id
        //元数据详情
        metadataInfoDTO: {
          apiId: "", //api类型使用
          indexCode: "",
          metadataSourceId: "", // 数据库id 数据库类型使用
          metadataTableId: "", //表id数据库类型使用
          saveFields: [],
          //  {
          //   //保存字段 api类型、数据库类型共用
          //   fieldId: "", //字段id数据库类型使用 对应id
          //   fieldName: "",//字段id数据库类型使用 对应id
          // },
          conditionFields: [],
          //  {
          //   //条件字段 api类型、数据库类型共用
          //   fieldId: "", //字段id 数据库类型使用 对应id
          //   fieldName: "",//字段名称api类型使用 对应reqParams.paramName
          //   defaultValue: "", //默认值 api类型、数据库类型共用 对应resqarams.defaultValue
          //   inputValue: "", //录入值api类型、数据库类型共用 用户输入
          // },
          compareFields: [],
          //  {
          //   //比对字段api类型、数据库类型共用
          //   fieldId: "",//数据库类型使用 对应id
          //   fieldName: "", //api类型使用 对应resParams.paramName
          // },

          sortFields: [],
          //排序字段

        }
      },
      queryTypeList: [
        { label: this.$t('cip.dc.offlineDComission.dengyu'), value: "1", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.budengyu'), value: "2", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.qujian'), value: "3", type: "1" },
        { label: this.$t('cip.dc.offlineDComission.dayu'), value: "4", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.xiaoyu'), value: "5", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.dadengyu'), value: "6", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.xiaodengyu'), value: "7", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.baohan'), value: "8", type: "1" },
        { label: this.$t('cip.dc.offlineDComission.bubaohan'), value: "9", type: "1" },
      ],
      tableID: "",
      tableData: [],
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      treeWay: "",
      treeWay2: "",

      SJKOP: [],//数据库下拉
      SBMOP: [],//数据库表名下拉
      APIOP: [],//api下拉
      SBZDbc: [], //表字段 保存
      SBZDtj: [], //表字段 条件
      SBZDdb: [], //表字段 对比
      SBZDpx: [], //排序字段
      CanEdit: false, //控制右侧操作
      tableTotal: 0,
      drawer: false, //预览
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0
      },
      tableLoading: false,
      gridRowBtn: [
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "grid-edit",
          remark: 'edit',
          type: "text",
          icon: ""
        },
        {
          label: this.$t('cip.dc.dataobject.title.tip2'),
          emit: "grid-see",
          remark: 'see',
          type: "text",
          icon: ""
        },

        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "grid-romve",
          remark: 'remove',
          type: "text",
          icon: ""
        },


      ],
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-add",
        type: "button",
        remark: '',
        icon: ""
      },
      {
        label: this.$t('cip.cmn.btn.saveBackBtn'),
        emit: "head-save-back",
        type: "button",
        icon: ""
      },
      {
        label: this.$t("cip.cmn.btn.celBtn"),
        emit: "head-back",
        type: "button",
        icon: "",
        loading: false,
      },
      ],
    };
  },
  mounted() {
    this.getdemotree()
    if (this.$route.query.type == "edit") {
      this.rowEdit(this.$route.query.id)
    }
  },

  methods: {
    toback() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

    //编辑
    rowEdit(e, index) {
      getDTime({
        dict_code: "dynamic_data_type"
      }).then(res => {
        this.DtimeType = res.data.data
      })
      getDataOBJXQ(e).then(res => {
        this.listAdd.id = e
        this.listAdd.code = res.data.data.code
        this.listAdd.label = res.data.data.label
        this.listAdd.remark = res.data.data.remark
        this.listAdd.sourceType = res.data.data.sourceType
        this.listAdd.dataObjectTypeId = res.data.data.dataObjectTypeId


        if (this.listAdd.sourceType == 1) {

          this.listAdd.metadataInfoDTO.metadataSourceId = res.data.data.metadataInfoDTO.metadataSourceId
          this.listAdd.metadataInfoDTO.metadataTableId = res.data.data.metadataInfoDTO.metadataTableId
          this.listAdd.metadataInfoDTO.saveFields = res.data.data.metadataInfoDTO.saveFields
          this.listAdd.metadataInfoDTO.conditionFields = res.data.data.metadataInfoDTO.conditionFields
          this.listAdd.metadataInfoDTO.compareFields = res.data.data.metadataInfoDTO.compareFields
          this.listAdd.metadataInfoDTO.sortFields = res.data.data.metadataInfoDTO.sortFields


          this.MgetSourcesList()
          let cc = res.data.data.metadataInfoDTO.metadataSourceId

          getSourcesTableList2({ sourceId: cc, modelId: this.$route.query.mid }).then(res => {
            this.SBMOP = res.data.data
          })


          getColumnsList({ sourceId: cc, tableId: res.data.data.metadataInfoDTO.metadataTableId }).then(res => {
            this.SBZDbc = JSON.parse(JSON.stringify(res.data.data))



            this.$nextTick(() => {
              this.SBZDbc.forEach((item, index) => {
                this.listAdd.metadataInfoDTO.saveFields.forEach((item1) => {
                  if (item.id == item1.fieldId) {
                    this.$refs.SBZDbcTable.toggleRowSelection(this.SBZDbc[index], true)
                  }
                })
              })
            })

            this.SBZDpx = JSON.parse(JSON.stringify(res.data.data))
            this.SBZDpx.forEach((item) => {
              this.$set(item, "sortRule", "")

            })

            this.$nextTick(() => {
              this.SBZDpx.forEach((item, index) => {
                this.listAdd.metadataInfoDTO.sortFields.forEach((item1) => {
                  if (item.id == item1.fieldId) {
                    this.$refs.pxTable.toggleRowSelection(this.SBZDpx[index], true)
                    item.sortRule = item1.sortRule.toString()
                  }
                })
              })
            })


            // BCinput
            this.SBZDtj = JSON.parse(JSON.stringify(res.data.data))
            this.SBZDtj.forEach((item) => {
              this.$set(item, "BCinput", "")
              this.$set(item, "TimeType", "")
              this.$set(item, "gdtime", "")
              this.$set(item, "DTime", "")
              this.$set(item, "QueryType", "")
              this.$set(item, "startValue", "")
              this.$set(item, "endValue", "")
            })

            // inputValue
            this.$nextTick(() => {
              this.SBZDtj.forEach((item, index) => {
                // this.listAdd.metadataInfoDTO.compareFields.forEach((item1)=>{
                this.listAdd.metadataInfoDTO.conditionFields.forEach((item1) => {

                  if (item.id == item1.fieldId) {
                    console.log("...............1111");
                    this.$refs.SBZDtjTable.toggleRowSelection(this.SBZDtj[index], true)
                    item.BCinput = item1.inputValue

                    item.TimeType = item1.dateType.toString()
                    // if (item1.beginTime) {
                    //   item.TimeType = '1'
                    // }
                    // if (item1.dynamicDateType) {
                    //   item.TimeType = '2'
                    // }
                    item.gdtime = [item1.beginTime, item1.endTime]
                    item.DTime = item1.dynamicDateType
                    item.QueryType = item1.queryType.toString()
                    item.startValue = item1.startValue
                    item.endValue = item1.endValue




                  }
                })
              })
            })


            this.SBZDdb = JSON.parse(JSON.stringify(res.data.data))

            this.$nextTick(() => {
              this.SBZDdb.forEach((item, index) => {
                // this.listAdd.metadataInfoDTO.conditionFields.forEach((item1)=>{
                this.listAdd.metadataInfoDTO.compareFields.forEach((item1) => {

                  if (item.id == item1.fieldId) {
                    console.log("...............");
                    this.$refs.SBZDdbTable.toggleRowSelection(this.SBZDdb[index], true)
                  }
                })
              })
            })


          })


        }

        if (this.listAdd.sourceType == 2) {
          this.listAdd.metadataInfoDTO.apiId = res.data.data.metadataInfoDTO.apiId


          this.listAdd.metadataInfoDTO.saveFields = res.data.data.metadataInfoDTO.saveFields
          this.listAdd.metadataInfoDTO.conditionFields = res.data.data.metadataInfoDTO.conditionFields
          this.listAdd.metadataInfoDTO.compareFields = res.data.data.metadataInfoDTO.compareFields

          getDataApisList({}).then(res => {
            this.APIOP = res.data.data
          })

          getDataApisXQ(res.data.data.metadataInfoDTO.apiId).then(res => {
            this.SBZDbc = JSON.parse(JSON.stringify(res.data.data.resParams))

            this.$nextTick(() => {
              this.SBZDbc.forEach((item, index) => {
                this.listAdd.metadataInfoDTO.saveFields.forEach((item1) => {
                  if (item.fieldName == item1.fieldName) {
                    console.log("...............");
                    this.$refs.SBZDbcTable2.toggleRowSelection(this.SBZDbc[index], true)
                  }
                })
              })
            })

            this.SBZDtj = JSON.parse(JSON.stringify(res.data.data.reqParams))

            this.SBZDtj.forEach((item) => {
              this.$set(item, "BCinput", "")
            })

            this.$nextTick(() => {
              this.SBZDtj.forEach((item, index) => {

                this.listAdd.metadataInfoDTO.conditionFields.forEach((item1) => {

                  if (item.paramName == item1.fieldName) {

                    this.$refs.SBZDtjTable2.toggleRowSelection(this.SBZDtj[index], true)
                    item.BCinput = item1.inputValue

                  }
                })
              })
            })


            this.SBZDdb = JSON.parse(JSON.stringify(res.data.data.resParams))

            this.$nextTick(() => {
              this.SBZDdb.forEach((item, index) => {
                this.listAdd.metadataInfoDTO.compareFields.forEach((item1) => {
                  if (item.fieldName == item1.fieldName) {
                    this.$refs.SBZDdbTable2.toggleRowSelection(this.SBZDdb[index], true)
                  }
                })
              })
            })


          })


        }
        if (this.listAdd.sourceType == 3) {
          this.listAdd.metadataInfoDTO.indexCode = res.data.data.metadataInfoDTO.indexCode


          this.listAdd.metadataInfoDTO.saveFields = res.data.data.metadataInfoDTO.saveFields
          this.listAdd.metadataInfoDTO.conditionFields = res.data.data.metadataInfoDTO.conditionFields
          this.listAdd.metadataInfoDTO.compareFields = res.data.data.metadataInfoDTO.compareFields

          this.MGetzbList()

          // let aa = "1_H"
          let dd = res.data.data.metadataInfoDTO.indexCode
          getzbfieldList({ indexCode: dd }).then(res => {

            let cc = res.data.data
            this.SBZDbc = []
            cc.forEach(element => {
              this.SBZDbc.push({ fieldName: element })
              if (element == "_time") {
                this.SBZDtj = [{ paramName: "_time", TimeType: "", gdtime: [], DTime: "" }]

              }
            });

            this.$nextTick(() => {
              this.SBZDbc.forEach((item, index) => {
                this.listAdd.metadataInfoDTO.saveFields.forEach((item1) => {
                  if (item.fieldName == item1.fieldName) {

                    this.$refs.SBZDbcTable3.toggleRowSelection(this.SBZDbc[index], true)
                  }
                })
              })
            })



            this.SBZDtj.forEach((item) => {
              this.$set(item, "TimeType", "")
              this.$set(item, "gdtime", "")
              this.$set(item, "DTime", "")
              this.$set(item, "startValue", "")
              this.$set(item, "endValue", "")
            })

            // inputValue
            this.$nextTick(() => {
              this.SBZDtj.forEach((item, index) => {
                this.listAdd.metadataInfoDTO.conditionFields.forEach((item1) => {

                  if (item.paramName == item1.fieldName) {
                    this.$refs.SBZDtjTable3.toggleRowSelection(this.SBZDtj[index], true)

                    // if (item1.beginTime) {
                    //   item.TimeType = '1'
                    // }
                    // if (item1.dynamicDateType) {
                    //   item.TimeType = '2'
                    // }
                    item.TimeType = item1.dateType.toString()
                    item.gdtime = [item1.beginTime, item1.endTime]
                    item.DTime = item1.dynamicDateType
                    item.startValue = item1.startValue
                    item.endValue = item1.endValue




                  }
                })
              })
            })


          })




        }


      })
    },



    toStr(str) {
      let data
      if (str.tableComment) {
        data = str.tableComment
      } else {
        data = str.tableName;
      }
      return decodeURI(data);
    },
    //树
    getdemotree() {
      getDataTree({ object: 0 }).then(res => {
        if (res.status == 200) {
          this.treeData = res.data.data
        }
      })
    },






    Addclose() {
      this.addTreeData.parentId = "0"
      this.addTreeData.name = ""
      this.addTreeData.type = "0"

    },




    //扁平化 树
    flatten(arr) {
      let result = [];
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children && arr[i].children.length > 0) {
          result = result.concat(this.flatten(arr[i].children));
          arr[i].children = []
          result = result.concat(arr[i])
        } else {
          result.push(arr[i]);
        }
      }
      return result;
    },

















    //选择类型
    ChooseType(e) {
      this.SBZDbc = [] //表字段 保存
      this.SBZDtj = [] //表字段 条件
      this.SBZDdb = [] //表字段 对比

      //数据库
      if (e == 1) {
        this.listAdd.metadataInfoDTO.apiId = ''
        this.MgetSourcesList()
      }
      //api
      if (e == 2) {
        this.listAdd.metadataInfoDTO.metadataSourceId = ''
        this.listAdd.metadataInfoDTO.metadataTableId = ''
        getDataApisList({}).then(res => {
          this.APIOP = res.data.data

        })
      }
      //指标
      if (e == 3) {
        this.listAdd.metadataInfoDTO.apiId = ''
        this.MGetzbList()
      }
      if (e == 4) {
        this.listAdd.metadataInfoDTO.apiId = ''
        this.MGetzbjkList()
      }
    },
    //获取指标
    MGetzbList() {
      let parms = {
        pageNum: 1, size: 99999,
      }
      objgetzb(parms).then(res => {
        this.zbList = res.data.data.data.records
      }).catch(res => {
      })
    },
     //获取指标接口
     MGetzbjkList() {
      let parms = {
        pageNum: 1, size: 99999,
      }
      objgetzbjk(parms).then(res => {
        this.zbjkList = res.data.data.records
      }).catch(res => {
      })
    },

    changezb(e) {
      getzbfieldList({ indexCode: e }).then(res => {

        let cc = res.data.data
        this.SBZDbc = []
        cc.forEach(element => {
          this.SBZDbc.push({ fieldName: element })
          if (element == "_time") {
            this.SBZDtj = [{ paramName: "_time", TimeType: "", gdtime: [], DTime: "" }]

          }
        });



      })
    },

    changezbjk(e){
      getzbjkfieldList({intfCode:e}).then(res=>{
        
      })
    },


    // 获取数据库
    MgetSourcesList() {
      // getSourcesList({}).then(res => {
      //   this.SJKOP = res.data.data
      // })

      getSourcesList2({ modelId: this.$route.query.mid }).then(res => {
        this.SJKOP = res.data.data
      })

    },

    //选择数据库名后 获取表
    ChooseSJKOP(e) {
      // getSourcesTableList({ sourceId: e }).then(res => {
      //   this.SBMOP = res.data.data
      // })
      this.listAdd.metadataInfoDTO.metadataTableId = ""
      getSourcesTableList2({ sourceId: e, modelId: this.$route.query.mid }).then(res => {
        this.SBMOP = res.data.data
      })

    },
    //选择表 后获取表字段
    ChooseSBMOP(e) {
      getColumnsList({ sourceId: this.listAdd.metadataInfoDTO.metadataSourceId, tableId: e }).then(res => {
        console.log(res);
        this.SBZDbc = JSON.parse(JSON.stringify(res.data.data))
        // BCinput
        this.SBZDtj = JSON.parse(JSON.stringify(res.data.data))
        this.SBZDpx = JSON.parse(JSON.stringify(res.data.data))
        this.SBZDpx.forEach((item) => {
          this.$set(item, "sortRule", "")
        })
        this.SBZDtj.forEach((item) => {
          this.$set(item, "BCinput", "")
          this.$set(item, "TimeType", "")
          this.$set(item, "gdtime", "")
          this.$set(item, "DTime", "")
          this.$set(item, "QueryType", "")
          this.$set(item, "startValue", "")
          this.$set(item, "endValue", "")
          // this.$set(item, "inputValue", "")
        })
        this.SBZDdb = JSON.parse(JSON.stringify(res.data.data))
      })
    },






    //选择api列表后
    ChooseAPIOP(e) {

      // this.listAdd.metadataInfoDTO.apiId = e


      getDataApisXQ(e).then(res => {
        this.SBZDbc = JSON.parse(JSON.stringify(res.data.data.resParams))
        this.SBZDtj = JSON.parse(JSON.stringify(res.data.data.reqParams))
        this.SBZDtj.forEach((item) => {
          this.$set(item, "BCinput", "")
        })
        this.SBZDdb = JSON.parse(JSON.stringify(res.data.data.resParams))
      })
    },


    //新增数据对象 表格数据
    AddObjTable(e) {


      //数据库类型
      if (this.listAdd.sourceType == 1) {

        var aSBZDbcTable = this.$refs.SBZDbcTable.selection
        var aSBZDdbTable = this.$refs.SBZDdbTable.selection
        var aSBZDtjTable = this.$refs.SBZDtjTable.selection
        var apxTable = this.$refs.pxTable.selection
        this.listAdd.metadataInfoDTO.apiId = undefined
        this.listAdd.metadataInfoDTO.indexCode = undefined
        if (this.$route.query.type == "add") {
          this.listAdd.id = null
        }

        //保存字段
        var a1 = []
        aSBZDbcTable.forEach((item) => {
          a1.push({
            fieldId: item.id,
            // fieldName:item.columnName
          })
        })
        this.listAdd.metadataInfoDTO.saveFields = a1

        //对比字段
        var a2 = []
        aSBZDdbTable.forEach((item) => {
          a2.push({
            fieldId: item.id,
          })
        })
        this.listAdd.metadataInfoDTO.compareFields = a2


        //条件字段
        var a3 = []
        aSBZDtjTable.forEach((item) => {

          a3.push({
            fieldId: item.id,
            defaultValue: "",
            inputValue: item.BCinput,
            dateType: item.TimeType,
            beginTime: item.gdtime[0],
            endTime: item.gdtime[1],
            dynamicDateType: item.DTime,
            queryType: item.QueryType,
            startValue: item.startValue,
            endValue: item.endValue,
            // inputValue:item.inputValue,


          })


        })



        var a4 = []
        apxTable.forEach(item => {
          a4.push({
            fieldId: item.id,
            sortRule: item.sortRule,
          })
        });


        // dataType
        this.listAdd.metadataInfoDTO.conditionFields = a3
        this.listAdd.metadataInfoDTO.sortFields = a4
        this.listAdd.modelId = this.$route.query.mid
        this.listAdd.dataObjectTypeId = this.$route.query.mid

        let canAdd = true
        a3.forEach(element => {
          if (element.dateType) {
            if (element.dateType == 1) {
              if (!element.beginTime || !element.endTime) {
                canAdd = false
                this.$message({
                  message: this.$t('cip.dc.dataobjDComission.msg1'),
                  type: 'warning'
                });
              }
            }
            if (element.dateType == 2) {

              if (!element.dynamicDateType) {
                canAdd = false
                this.$message({
                  message: this.$t('cip.dc.dataobjDComission.msg1'),
                  type: 'warning'
                });
              }
            }

          }

        });

        if (canAdd || a3.length == 0) {
          AddObjectList(this.listAdd).then(res => {
            this.$message({
              message: this.$t('cip.dc.offlineDComission.savemsg'),
              type: 'success'
            });
            if (e == "back") {
              this.$router.$avueRouter.closeTag();
              this.$router.back();
            }
          })
        }




      }

      //api类型
      if (this.listAdd.sourceType == 2) {

        this.listAdd.metadataInfoDTO.metadataSourceId = undefined
        this.listAdd.metadataInfoDTO.indexCode = undefined
        if (this.$route.query.type == "add") {
          this.listAdd.id = null
        }


        var bSBZDbcTable = this.$refs.SBZDbcTable2.selection
        var bSBZDdbTable = this.$refs.SBZDdbTable2.selection
        var bSBZDtjTable = this.$refs.SBZDtjTable2.selection


        //保存字段
        var b1 = []
        bSBZDbcTable.forEach((item) => {
          b1.push({
            fieldName: item.fieldName
          })
        })
        this.listAdd.metadataInfoDTO.saveFields = b1


        //对比字段
        var b2 = []
        bSBZDdbTable.forEach((item) => {
          b2.push({
            fieldName: item.fieldName
          })
        })
        this.listAdd.metadataInfoDTO.compareFields = b2


        //条件字段
        var b3 = []
        bSBZDtjTable.forEach((item) => {
          b3.push({
            // fieldId:item.id,
            fieldName: item.paramName,
            defaultValue: "",
            inputValue: item.BCinput
          })
        })
        this.listAdd.metadataInfoDTO.conditionFields = b3
        this.listAdd.modelId = this.$route.query.mid
        this.listAdd.dataObjectTypeId = this.$route.query.mid

        AddObjectList(this.listAdd).then(res => {

          this.$message({
            message: this.$t('cip.dc.offlineDComission.savemsg'),
            type: 'success'
          });
          if (e == "back") {
            this.$router.$avueRouter.closeTag();
            this.$router.back();
          }


        })


      }

      //指标类型
      if (this.listAdd.sourceType == 3) {

        this.listAdd.metadataInfoDTO.metadataSourceId = undefined

        if (this.$route.query.type == "add") {
          this.listAdd.id = null
        }


        var bSBZDbcTable3 = this.$refs.SBZDbcTable3.selection
        var bSBZDtjTable3 = this.$refs.SBZDtjTable3.selection
        //条件字段
        var ba3 = []
        bSBZDtjTable3.forEach((item) => {

          ba3.push({

            fieldName: item.paramName,
            beginTime: item.gdtime[0],
            endTime: item.gdtime[1],
            dynamicDateType: item.DTime,
            dateType: item.TimeType,


          })


        })


        ba3.forEach(element => {
          if (element.dateType) {
            if (element.dateType == 1) {
              if (!element.beginTime || !element.endTime) {
                this.$message({
                  message: this.$t('cip.dc.dataobjDComission.msg1'),
                  type: 'warning'
                });
                return
              }
            }
            if (element.dateType == 2) {

              if (!element.dynamicDateType) {
                this.$message({
                  message: this.$t('cip.dc.dataobjDComission.msg1'),
                  type: 'warning'
                });
                return
              }
            }

          }

        });



        //保存字段
        var c1 = []
        bSBZDbcTable3.forEach((item) => {
          c1.push({
            fieldName: item.fieldName
          })
        })
        this.listAdd.metadataInfoDTO.saveFields = c1
        this.listAdd.metadataInfoDTO.apiId = undefined
        this.listAdd.metadataInfoDTO.indexCode
        this.listAdd.metadataInfoDTO.compareFields = []
        this.listAdd.metadataInfoDTO.conditionFields = ba3
        this.listAdd.modelId = this.$route.query.mid
        this.listAdd.dataObjectTypeId = this.$route.query.mid

        AddObjectList(this.listAdd).then(res => {

          this.$message({
            message: this.$t('cip.dc.offlineDComission.savemsg'),
            type: 'success'
          });
          if (e == "back") {
            this.$router.$avueRouter.closeTag();
            this.$router.back();
          }


        })


      }


    },

    closedrawer() {
      this.tableData4 = []
      this.tableData4List = []
    },








    //时间类型
    changeTimeType(e, a) {
      if (e == 1) {
        a.gdtime = ''
      }
      if (e == 2) {
        a.DTime = ''
        getDTime({
          dict_code: "dynamic_data_type"
        }).then(res => {
          this.DtimeType = res.data.data
        })
      }
    },
    changeQueryType(e, a) {
      a.BCinput = ''
      a.startValue = ''
      a.endValue = ''
    }


  },


};

</script>
<style lang='less' scoped>
.btbox {
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
}

.dialog-footer1 {
  display: flex;
  justify-content: flex-end;
}

::v-deep .avue-crud .el-table {
  height: calc(100vh - 218px) !important;
  max-height: calc(100vh - 218px) !important;
}
</style>
